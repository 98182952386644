import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
  CHAT_API_URL,
  DATA_API_URL,
  FORUM_API_URL,
  API_URL as baseUrl,
} from '@constants/api.constants';
import { IAuthResponse, cookieService } from '@lib/cookie.service';

export const baseQuery = fetchBaseQuery({ baseUrl });

const tokenQuery = fetchBaseQuery({
  baseUrl,
  prepareHeaders: (headers) => {
    const { accessToken, refreshToken } = cookieService.getAuthCookies();
    if (accessToken && refreshToken) {
      headers.set('Authorization', `Bearer ${accessToken}`);
      headers.set('Refresh-token', refreshToken);
    }
    return headers;
  },
});

export const authQuery = async (args, api, extraOptions) => {
  let result = await tokenQuery(args, api, extraOptions);

  if (result.error && 'status' in result.error) {
    // TODO: investigate about 403
    if (result.error.status === 401) {
      const { refreshToken } = cookieService.getAuthCookies();

      const response = await tokenQuery(
        {
          url: '/auth/refresh-token',
          method: 'POST',
          body: {
            refreshToken,
          },
        },
        api,
        extraOptions,
      );
      if ('data' in response) {
        cookieService.setAuthCookies(response.data as IAuthResponse);
        result = await tokenQuery(args, api, extraOptions);
      } else {
        // TODO: logout
      }
    }
  }
  return result;
};

export const dataQuery = fetchBaseQuery({
  baseUrl: DATA_API_URL,
  prepareHeaders: (headers) => {
    const { accessToken, refreshToken } = cookieService.getAuthCookies();
    if (accessToken) {
      headers.set('Authorization', `Bearer ${accessToken}`);
    }
    if (refreshToken) {
      headers.set('Refresh-token', refreshToken);
    }
    return headers;
  },
});

export const forumQuery = fetchBaseQuery({
  baseUrl: FORUM_API_URL,
  prepareHeaders: (headers) => {
    const { accessToken, refreshToken } = cookieService.getAuthCookies();
    if (accessToken) {
      headers.set('Authorization', `Bearer ${accessToken}`);
    }
    if (refreshToken) {
      headers.set('Refresh-token', refreshToken);
    }
    return headers;
  },
});

export const chatQuery = fetchBaseQuery({ baseUrl: CHAT_API_URL });
