import { getCookie, setCookie, deleteCookie } from 'cookies-next';

export interface IAuthResponse {
  accessToken: string;
  refreshToken: string;
  userId: string;
}

export const COOKIES_MAP = {
  accessToken: 'KOR_LINK-accessToken',
  refreshToken: 'KOR_LINK-refreshToken',
  userId: 'KOR_LINK-userId',
};

export const cookieService = {
  setAuthCookies({ accessToken, refreshToken, userId }: IAuthResponse) {
    setCookie(COOKIES_MAP.accessToken, accessToken);
    setCookie(COOKIES_MAP.refreshToken, refreshToken);
    setCookie(COOKIES_MAP.userId, userId);
  },
  clearAuthCookies() {
    deleteCookie(COOKIES_MAP.accessToken);
    deleteCookie(COOKIES_MAP.refreshToken);
    deleteCookie(COOKIES_MAP.userId);
  },
  getAuthCookies() {
    return {
      accessToken: getCookie(COOKIES_MAP.accessToken) || null,
      refreshToken: (getCookie(COOKIES_MAP.refreshToken) as string) || null,
      userId: getCookie(COOKIES_MAP.userId) || null,
    };
  },
  isAuthed() {
    const data = this.getAuthCookies();
    const isAuth = !Object.values(data).some((el) => el === null);
    return isAuth;
  },
  getUserId() {
    return getCookie(COOKIES_MAP.userId);
  },
};
