// TODO: Make this common or move to packages

export const API_URL = process.env['NEXT_PUBLIC_API_URL'];
export const DATA_API_URL = process.env['NEXT_PUBLIC_DATA_API_URL'];
export const FAN_URL = process.env['NEXT_PUBLIC_FAN_WEB_URL'];
export const CHAT_API_URL = process.env['NEXT_PUBLIC_CHAT_API_URL'];
export const FORUM_API_URL = process.env['NEXT_PUBLIC_FORUM_API_URL'];

export const MOCKED_USER = {
  email: process.env['NEXT_PUBLIC_MOCKED_USER_EMAIL'],
  password: process.env['NEXT_PUBLIC_MOCKED_USER_PASSWORD'],
};

export const IS_DEVELOPMENT = process.env['NODE_ENV'] === 'development';
export const IS_PRODUCTION = process.env['NEXT_PUBLIC_PRODUCTION'];
