import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { IUser } from '@ui/components';

export interface IPublicProfile {
  avatar: string;
  name: string;
  displayName: string;
  banner: string;
}

export interface AppState {
  user: IUser | null;
  bannerURL: string | null;
}

const initialState: AppState = {
  user: {
    avatar: '',
    name: '',
    displayName: '',
    email: '',
    banner: '',
    id: '',
  },
  bannerURL: null,
};

export const counterSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<IUser>) => {
      state.user = action.payload;
    },
    setBannerUrl: (state, action) => {
      state.bannerURL = action.payload;
    },
    removeUser: () => initialState,
  },
});

// Action creators are generated for each case reducer function
export const { setUser, setBannerUrl, removeUser } = counterSlice.actions;

export const appReducer = counterSlice.reducer;
